<script setup lang="ts">
import { useMedia } from '~/store/media'
import type { HasSeriesMediaType } from '~/types'
import { validateMediaType } from '~/utils/validate'

const mediaStore = useMedia()

definePageMeta({
  key: (route) => route.fullPath,
  validate: ({ params }) => validateMediaType(params.type)
})

watchEffect(() => {
  if (useUser().userInfo.id && isBoolean(useUser().isRealUser)) {
    handleMediaDetail()
  }
})

const pageNum = ref(1)
const mediaType = useRouteParam<HasSeriesMediaType>('type')
const videoId = useRouteParam<string>('id')

async function handleMediaDetail() {
  try {
    await mediaStore.handleMediaDetail(videoId.value)
  } catch (e) {}
}

async function handleGuessPrefer() {
  try {
    await mediaStore.handleGuessPrefer({ pageNum: pageNum.value, videoSiteId: videoId.value })
    pageNum.value++
  } catch (e) {}
}

await handleMediaDetail()
handleGuessPrefer()

useSeoMeta({
  title: mediaStore.mediaDetail.videoName
})
</script>

<template>
  <div class="lg:flex">
    <NuxtLink
      class="mb-5 lg:mb-0 lg:max-w-25%"
      mb-2
      flex
      lg:mb-0
      :to="`/play/${mediaType}/${videoId}/${mediaStore.videoSeriesList[0]?.videoSeriesId}`"
    >
      <ImgWrapper class="mx-a my-0 max-w-full border-rd-2" :src="`${mediaStore.videoVerticalUrl}`" />
    </NuxtLink>
    <div class="~ flex flex-col flex-justify-between pl-2 lg:max-w-75% lg:pl-5">
      <VideoIntroduction :type="mediaType" :infos="mediaStore.mediaDetail" @refresh="handleMediaDetail" />
      <div flex>
        <NuxtLink :to="`/play/${mediaType}/${videoId}/${mediaStore.videoSeriesList[0]?.videoSeriesId}`">
          <BaseButton text="立即播放" class="flex items-center border-rd-5 px-3 py-0.5 lg:px-5 lg:py-1">
            <template #icon>
              <BaseIcon name="play" size="0.8" mr-1 inline-block color="#fff" />
            </template>
          </BaseButton>
        </NuxtLink>
      </div>
    </div>
  </div>
  <BannerPage :banner-type="{ type: 2, code: 73 }" mt-4 xl:mt-5 />
  <VideoSeriesList :type="mediaType" :list="mediaStore.videoSeriesList" has-radius my-4 xl:my-5 />
  <MediaGrid @header-click="handleGuessPrefer">
    <template #header> 猜你喜欢 </template>
    <MediaCard
      v-for="cards in mediaStore.guessPreferList"
      :key="cards.videoSiteId"
      :type="getVideoType(cards.videoSiteType)"
      :item="cards"
    />
  </MediaGrid>
</template>
Í
